var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"align-top":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm10":"","md10":""}},[_c('v-sheet',{staticClass:"pa-2",attrs:{"color":"white","elevation":"20"}},[_c('v-btn',{staticClass:"toptop",attrs:{"fab":"","icon":"","fixed":"","top":"","right":""},on:{"click":_vm.newProduct}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-data-table',{staticClass:"table-cursor mt-3",attrs:{"headers":_vm.headers[_vm.$vuetify.breakpoint.xsOnly],"items":_vm.items,"no-data-text":"No hay productos registrados","items-per-page":-1,"item-key":"code","mobile-breakpoint":"0","group-by":"category","show-group-by":"","search":_vm.search,"sort-by":"category,product_name"},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"hide-details":"auto","label":"Proveedor","items":_vm.suppliers,"outlined":"","item-text":"name","item-value":"code","required":"","return-object":""},on:{"change":_vm.get_items},model:{value:(_vm.sSelected),callback:function ($$v) {_vm.sSelected=$$v},expression:"sSelected"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"hide-details":"","append-icon":"mdi-magnify","outlined":"","placeholder":"Buscar producto ...","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},(_vm.item.picture !== '')?{key:"item.picture",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"contain":"","width":"60px","src":_vm.getImagePhoto(item.account, item.supplier, item.picture)}})]}}:null,{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.product_name))]),(_vm.$vuetify.breakpoint.xsOnly)?_c('span',[_c('small',[_vm._v(" ("+_vm._s(item.category)+")")]),_c('br'),_c('small',[_vm._v(" "+_vm._s(item.size)+" "+_vm._s(item.uom)+" ")])]):_vm._e()]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.size + " " + item.uom)+" "),_c('br')]}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$ " + parseFloat(item.cost).toFixed(2))+" ")]}},{key:"item.fob",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$ " + parseFloat(item.fob).toFixed(2))+" ")]}},{key:"item.cif",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$ " + parseFloat(item.cif).toFixed(2))+" ")]}},{key:"item.fas",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$ " + parseFloat(item.fas).toFixed(2))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$ " + parseFloat(item.price).toFixed(2))+" ")]}},{key:"item.cost_uom",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$ " + parseFloat(item.cost_uom).toFixed(2))+" ")]}},{key:"item.price_uom",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$ " + parseFloat(item.price_uom).toFixed(2))+" ")]}},{key:"item.difference",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((((parseFloat(item.price_uom) - parseFloat(item.cost_uom)) / parseFloat(item.price_uom)) * 100).toFixed(2))+" ")]}}],null,true)}),_c('ProductForm',{attrs:{"table":_vm.table,"item":_vm.item,"dialog":_vm.dialog,"newItem":_vm.newItem,"categories":_vm.categories,"store":_vm.store,"subcategories":_vm.subcategories,"brands":_vm.brands,"suppliers":this.supplier_selected.code},on:{"close":function($event){_vm.dialog = false},"refresh":function($event){return _vm.refresh()}}}),_c('v-navigation-drawer',{staticClass:"navigation-drawer",attrs:{"temporary":"","right":"","width":"480","value":_vm.dialog_smart,"app":""}},[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Lista maestra")]),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){_vm.dialog_smart = !_vm.dialog_smart}}},[_vm._v("mdi-close")])],1),_c('v-card',{attrs:{"height":"100vh"}},[_c('v-card-text',{staticClass:"scroll pa-0",attrs:{"color":"transparent"}},[_c('v-expansion-panels',{attrs:{"accordion":"","flat":""}},_vm._l((_vm.smart_ctgry),function(item,i){return _c('v-expansion-panel',{key:i},[_c('v-card',{attrs:{"tile":"","flat":""}},[_c('v-expansion-panel-header',[_c('v-list-item',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(i)}})],1)],1),_c('v-expansion-panel-content',[_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.smart_ctgry[i]),function(child,index){return _c('v-list-item',{key:index,staticClass:"ma-0 pa-0",on:{"click":function($event){return _vm.add_to_supplier(child)}}},[_c('v-list-item-avatar',{attrs:{"size":"64"}},[(child.picture)?_c('v-img',{attrs:{"cover":"","src":_vm.getImagePhoto(
                                child.account,
                                child.supplier,
                                child.picture
                              )}}):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(child.product_name)}}),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(child.size + ' ' + child.uom)+" ")])],1),_c('v-list-item-action',[_vm._v(" "+_vm._s("$ " + parseFloat(child.price).toLocaleString())+" ")])],1)}),_c('v-divider')],2)],1)],1)],1)}),1)],1),_c('v-card-actions',{staticClass:"fixed-footer"})],1),_c('v-bottom-navigation',{attrs:{"color":"secondary","app":""}},[_c('v-row',{staticClass:"pt-2",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"12","cols":"12"}},[_c('v-text-field',{attrs:{"hide-details":"","append-icon":"mdi-magnify","outlined":"","rounded":"","dense":"","placeholder":"Buscar producto Smart ..."},on:{"keypress":function($event){return _vm.update_selected()}},model:{value:(_vm.search_smart),callback:function ($$v) {_vm.search_smart=$$v},expression:"search_smart"}})],1)],1)],1)],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.loading_status),callback:function ($$v) {_vm.loading_status=$$v},expression:"loading_status"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" Cargando ... "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }